import OpenAI from 'openai';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
if (!apiKey) {
  throw new Error('OpenAI API key is required. Please add VITE_OPENAI_API_KEY to your .env file.');
}

// Create OpenAI client with production configuration
const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true, // Required for browser usage
  timeout: 30000,
  maxRetries: 3,
  defaultQuery: {
    'api-version': '2024-02',
  },
  defaultHeaders: {
    'X-Request-ID': "1234567890",
    'OpenAI-Beta': 'assistants=v1',
  }
});

// Add request/response logging for production monitoring
const logApiCall = async (operation: string, func: () => Promise<any>) => {
  const requestId = crypto.randomUUID();
  const startTime = Date.now();

  try {
    const result = await func();
    return result;
  } catch (error: any) {
    const duration = Date.now() - startTime;
    
    console.error(`[OpenAI] Failed ${operation} (${requestId}) after ${duration}ms`, {
      error: {
        message: error.message,
        code: error.code,
        type: error.type,
        status: error.status
      },
      requestId,
      duration
    });

    throw error;
  }
};

// Wrap OpenAI client with logging
export const enhancedOpenAI = {
  chat: {
    completions: {
      create: (params: Parameters<typeof openai.chat.completions.create>[0]) =>
        logApiCall('chat.completions.create', () => openai.chat.completions.create(params))
    }
  }
};

export { enhancedOpenAI as openai };