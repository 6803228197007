import { useState } from 'react';
import { useAdmin } from '@/context/AdminContext';
// import { Office } from '@/lib/types';
import Button from '@/components/ui/Button';
import Modal from '@/components/ui/Modal';
import { Building, Pencil, Trash } from 'lucide-react';
import { generateId } from '@/lib/utils';
import { useAuth } from '@/context/AuthContext';
import {deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';

export default function Offices() {
  const { state, dispatch } = useAdmin();
  const { state:authState } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingOffice, setEditingOffice] = useState<any | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
  });

  // Sort offices alphabetically by name
  const sortedOffices = state?.offices?.sort((a:any, b:any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const office = {
      workspaceId: authState.user !== null && authState.user.id,
      id: editingOffice?.id || generateId(),
      name: formData.name,
      address: formData.address,
      createdAt: editingOffice?.createdAt || new Date().toISOString(),
    };

    try {
      if (!editingOffice) {
        await setDoc(doc(db, "offices", office.id), office);
      } else {
        const officeRef = doc(db, "offices", editingOffice.id);
        await updateDoc(officeRef, office);
      }
  
      dispatch({
        type: editingOffice && "UPDATE_OFFICE",
        payload: office,
      });
  
      setIsModalOpen(false);
      setEditingOffice(null);
      setFormData({ name: "", address: "" });
    } catch (error) {
      console.error("Error saving office:", error);
    }
  };

  const handleEdit = (office: any) => {
    setEditingOffice(office);
    setFormData({
      name: office.name,
      address: office.address,
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (confirm('Are you sure you want to delete this office?')) {
      try {
        await deleteDoc(doc(db, "offices", id));
        dispatch({
          type: "DELETE_OFFICE",
          payload: id,
        });
    
        console.log(`Office with ID: ${id} has been deleted successfully.`);
      } catch (error) {
        console.error(`Error deleting office with ID: ${id}`, error);
        dispatch({
          type: "SET_ERROR",
          payload: "Failed to delete office",
        });
      }
    }
  };

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Offices</h1>
        <Button onClick={() => setIsModalOpen(true)}>Add Office</Button>
      </div>

      <div className="mt-8">
        {sortedOffices.length === 0 ? (
          <div className="rounded-lg bg-white p-8 text-center">
            <Building className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No offices</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new office.</p>
          </div>
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Address
                        </th>
                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sortedOffices.map((office:any) => (
                        <tr key={office.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {office.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {office.address}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex justify-end space-x-2">
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => handleEdit(office)}
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => handleDelete(office.id)}
                              >
                                <Trash className="h-4 w-4" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingOffice(null);
          setFormData({ name: '', address: '' });
        }}
        title={editingOffice ? 'Edit Office' : 'Add Office'}
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
              required
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
              required
            />
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button type="submit" className="w-full sm:ml-3 sm:w-auto">
              {editingOffice ? 'Update' : 'Create'}
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                setIsModalOpen(false);
                setEditingOffice(null);
                setFormData({ name: '', address: '' });
              }}
              className="mt-3 w-full sm:mt-0 sm:w-auto"
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}