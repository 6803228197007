import { Category } from './categories';
import { Request, User } from './types';
import { generateId } from './utils';
import {  getAdminsByCategory } from './permissions';
import { RequestError, ValidationError } from './errors';
import { CATEGORIES } from './categories';
import { storage } from './storage';

export interface CreateRequestParams {
  title: string;
  category: Category;
  categoryId?: string;
  transcript: string;
  user: User;
  admins: User[];
  adminId?: string | null;
  audioUrl?: string | null;
  officeId?: string;
  workspaceId?: string;
  status?: 'Pending' | 'In Progress' | 'Completed';
  whisperData?: {
    confidence: number;
    segments?: Array<{ text: string; confidence: number }>;
  };
  originalAnalysis?: {
    title: string;
    category: Category;
    aiResponse: string;
  };
}

export async function createRequest(params: CreateRequestParams): Promise<{
  request: Request;
  notifications: Array<{
    id: string;
    userId: string;
    title: string;
    message: string;
    type: 'request_update' | 'new_comment' | 'status_change';
    read: boolean;
    createdAt: string;
    requestId: string;
  }>;
}> {
  try {
    // Validate required fields
    if (!((params.title as unknown) as string)?.trim()) {
      throw new ValidationError('Title is required');
    }
    if (!((params.category as unknown) as string)?.trim()) {
      throw new ValidationError('Category is required');
    }
    if (!(params.transcript as string)?.trim()) {
      throw new ValidationError('Transcript is required');
    }
    if (!params.user?.id) {
      throw new ValidationError('User is required');
    }
    if (!params.audioUrl) {
      throw new ValidationError('Recording is required');
    }
    if (!params.categoryId) {
      throw new ValidationError('Category ID is required');
    }

    // Initialize request object with validated data
    const request: Request = {
      id: generateId(),
      title: params.title.trim(),
      category: ((params.category as unknown) as string).trim(),
      categoryId: params.categoryId,
      transcript: params.transcript.trim(),
      audioUrl:params.audioUrl,
      status: params.status || 'Pending',
      createdAt: new Date().toISOString(),
      agentId: params.user.id,
      adminId: params?.adminId || null,
      comments: [],
      whisperData: params.whisperData,
      officeId: params?.officeId,
      workspaceId: params.workspaceId,
      originalAnalysis: params.originalAnalysis
    ? {
        title: params.originalAnalysis.title,
        category: (params.originalAnalysis.category as unknown) as string,
        aiResponse: params?.originalAnalysis?.aiResponse
      }
    : undefined,
    };

    const notifications: Array<{
      id: string;
      userId: string;
      title: string;
      message: string;
      type: 'request_update' | 'new_comment' | 'status_change';
      read: boolean;
      createdAt: string;
      requestId: string;
    }> = [];

    // Find eligible admin if not an AI request
    if (((params.category as unknown) as string ) !== CATEGORIES.AI) {
      // const eligibleAdmin = findEligibleAdmin(params.admins, request);
      // request.adminId = eligibleAdmin?.id || null;

      // If no admin is found and it's not already uncategorized,
      // assign to super admin catch-all
      if (!request.adminId && ((params.category as unknown) as string) !== CATEGORIES.UNCATEGORIZED) {
        const superAdmin = params.admins.find(a => 
          a.role === 'SUPER_ADMIN' && 
          a.email === 'tyler@joinvictory.com'
        );
        if (superAdmin) {
          request.adminId = superAdmin.id;
        }
      }

      // Create notifications for eligible admins
      const eligibleAdmins = getAdminsByCategory(params.admins, params.categoryId, params.user.officeIds[0]);
      eligibleAdmins.forEach(admin => {
        notifications.push({
          id: generateId(),
          userId: admin.id,
          title: 'New Request Assigned',
          message: `A new request "${params.title}" has been created and requires your attention.`,
          type: 'request_update',
          read: false,
          createdAt: new Date().toISOString(),
          requestId: request.id
        });
      });
    }

    // Save the request to storage
    try {
      await storage.createRequest(request);
    } catch (error) {
      console.error('Error saving request:', error);
      throw new RequestError('Failed to save request');
    }

    return { request, notifications };

  } catch (error) {
    console.error('Error creating request:', error);
    if (error instanceof RequestError || error instanceof ValidationError) {
      throw error;
    }
    throw new RequestError(
      'Failed to create request. Please try again.',
      'REQUEST_CREATION_FAILED'
    );
  }
}