import { Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import RequireRole from "./components/RequireRole";
import DashboardLayout from "./layouts/DashboardLayout";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Requests from "./pages/Requests";
import NewRequest from "./pages/NewRequest";
import Settings from "./pages/Settings";
import Offices from "./pages/admin/Offices";
import Categories from "./pages/admin/Categories";
import Users from "./pages/admin/Users";
import AuthActions from "./pages/AuthActions";
import NoAuth from "./components/NoAuth";
import OwnerRequired from "./components/OwnerRequired";
import SuperAdmins from "./pages/owner/SuperAdmins";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function AppRoutes() {
  return (
    <Routes>
      {/* Public routes */}
      <Route
        path="/"
        element={
          <NoAuth>
            <Home />
          </NoAuth>
        }
      />
      <Route
        path="/auth/action"
        element={
          <NoAuth>
            <AuthActions />
          </NoAuth>
        }
      />
      <Route
        path="/login"
        element={
          <NoAuth>
            <Login />
          </NoAuth>
        }
      />
      <Route
        path="/register"
        element={
          <NoAuth>
            <Register />
          </NoAuth>
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      {/* Agent routes */}
      <Route
        path="/dashboard/requests"
        element={
          <RequireAuth>
            <DashboardLayout>
              <Requests />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/requests/new"
        element={
          <RequireAuth>
            <RequireRole roles={["AGENT"]}>
              <DashboardLayout>
                <NewRequest />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/settings"
        element={
          <RequireAuth>
            <DashboardLayout>
              <Settings />
            </DashboardLayout>
          </RequireAuth>
        }
      />

      {/* Admin only routes */}

      <Route
        path="/dashboard/admin/approvals"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <OwnerRequired>
                <DashboardLayout>
                  <SuperAdmins />
                </DashboardLayout>
              </OwnerRequired>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/offices"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <DashboardLayout>
                <Offices />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/categories"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN"]}>
              <DashboardLayout>
                <Categories />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/admin/users"
        element={
          <RequireAuth>
            <RequireRole roles={["SUPER_ADMIN", "ADMIN"]}>
              <DashboardLayout>
                <Users />
              </DashboardLayout>
            </RequireRole>
          </RequireAuth>
        }
      />

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}
