import { useState } from "react";
import { useAdmin } from "@/context/AdminContext";
// import { Category } from '@/lib/types';
import Button from "@/components/ui/Button";
import CategoryTable from "@/components/categories/CategoryTable";
import CategoryForm from "@/components/categories/CategoryForm";
import EmptyState from "@/components/categories/EmptyState";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { generateId } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";

export default function Categories() {
  const { state, dispatch } = useAdmin();
  const {state:auth} = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<any | null>(null);
  

  const handleSubmit = async (formData: Omit<any, "id" | "createdAt">) => {
    const category: any = {
      id: editingCategory?.id || generateId(),
      name: formData.name,
      description: formData.description,
      createdAt: editingCategory?.createdAt || new Date().toISOString(),
      workspaceId:auth?.user?.id,
      default: editingCategory?.default || false
    };

    try {
      if (!editingCategory) {
        await setDoc(doc(db, "categories", category.id), category);
      } else {
        dispatch({
          type: editingCategory ? "UPDATE_CATEGORY" : "ADD_CATEGORY",
          payload: {
            id: editingCategory.id,
            name: category.name,
            description: category.description,
          },
        } as any);
      }
      setIsModalOpen(false);
      setEditingCategory(null);
    } catch (error) {
      console.error("Failed to add category to Firebase:", error);
    }
  };

  const handleEdit = (category: any) => {
    setEditingCategory(category);
    setIsModalOpen(true);
  };

  const handleDelete = (id: string) => {
    if (confirm("Are you sure you want to delete this category?")) {
      dispatch({ type: "DELETE_CATEGORY", payload: id } as any);
    }
  };
  
  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Categories</h1>
        <Button onClick={() => setIsModalOpen(true)}>Add Category</Button>
      </div>

      <div className="mt-8">
        {state.categories.length === 0 ? (
          <EmptyState />
        ) : (
          <CategoryTable
            categories={state.categories}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
      </div>

      <CategoryForm
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingCategory(null);
        }}
        onSubmit={handleSubmit}
        editingCategory={editingCategory}
      />
    </div>
  );
}
