import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { UserRole } from '@/lib/types';

interface RequireRoleProps {
  children: ReactNode;
  roles: any;
}

export default function RequireRole({ children, roles }: RequireRoleProps) {
  const { state } = useAuth();
  const location = useLocation();

  if (!state.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If user's role is not in allowed roles, redirect to dashboard
  if (!roles.includes(state.user.role)) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
}