import {  CheckCircleIcon, LucideMessageCircleX } from "lucide-react";
import { toast } from "react-toastify";

export const CustomToast = (type, toastLabel) => {
  // Delay the dismissal of the toast
  setTimeout(() => toast.dismiss(), 15000);

  // Render the custom toast
  return toast(`${toastLabel}`, {
    icon: type === 'success' ? <CheckCircleIcon/> : <LucideMessageCircleX />,
    position: "top-right",
    hideProgressBar: true,
    closeButton: true,
    className: `border-b-2 ${type === 'success' ? 'border-green-500' : 'border-red-500'}`,
  });
};
