import { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useNotifications } from "@/context/NotificationContext";
import { useRequests } from "@/context/RequestsContext";
import { RequestComment, RequestAttachment, Request } from "@/lib/types";
import { generateAIResponse } from "@/lib/openai";
import Button from "./ui/Button";
import { MessageSquare, Paperclip, Download } from "lucide-react";
import FileUpload from "./ui/FileUpload";

import { storage } from "@/lib/firebase/storage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useDownloader from "react-use-downloader";
import clsx from "clsx";
import { updateRequest } from "@/context/RequestsContext";
import { Input } from "antd";

const { TextArea } = Input;

const ATTACHMENT_PATH = "attachments";

interface RequestCommentsProps {
  requestId: string;
  comments: RequestComment[];
  originalAnalysis:
    | {
        aiResponse: string;
        category: string;
        title: string;
      }
    | undefined;
  request: Record<string, any>;
}

export default function RequestComments({
  requestId,
  comments,
  originalAnalysis,
  request,
}: RequestCommentsProps) {
  const { state: requestState, dispatch } = useRequests();
  const { state: authState } = useAuth();
  const { dispatch: notificationDispatch } = useNotifications();
  const [newComment, setNewComment] = useState("");
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const [isEditingNote, setIsEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState<string>(
    originalAnalysis?.aiResponse || ""
  );
  const [updatingNote, setUpdatingNote] = useState(false);

  const { size, elapsed, percentage, download, cancel, isInProgress } =
    useDownloader();

  const handleFileSelect = (files: File[]) => {
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleFileRemove = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const uploadFiles = async (files: File[]): Promise<RequestAttachment[]> => {
    const fileUploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `${ATTACHMENT_PATH}/${file.name}`);
      const metadata = {
        contentType: file.type,
      };
      await uploadBytes(fileRef, file, metadata);
      const downloadURL = await getDownloadURL(fileRef);

      return {
        id: crypto.randomUUID(),
        name: file.name,
        type: file.type,
        size: file.size,
        url: downloadURL,
      };
    });

    return Promise.all(fileUploadPromises);
  };

  const handleAddComment = async () => {
    if ((!newComment.trim() && selectedFiles.length === 0) || !authState.user) {
      return;
    }

    setError(null);
    setIsUploading(true);

    try {
      // Upload any attached files
      const attachments =
        selectedFiles.length > 0 ? await uploadFiles(selectedFiles) : undefined;

      // Create the comment
      const comment: RequestComment = {
        userId: authState.user.id,
        id: crypto.randomUUID(),
        requestId,
        content: newComment.trim(),
        author: authState.user.name,
        attachments,
        createdAt: new Date().toISOString(),
      };

      // Find the request
      const request = requestState.requests.find((r) => r.id === requestId);
      if (!request) {
        throw new Error("Request not found");
      }

      // Add the comment
      dispatch({
        type: "ADD_COMMENT",
        payload: { requestId, comment },
      });

      // Create notification based on user role
      if (
        authState.user.role === "ADMIN" ||
        authState.user.role === "SUPER_ADMIN"
      ) {
        // Notify agent
        const notification = {
          id: crypto.randomUUID(),
          userId: request.agentId,
          title: "New Comment Added",
          message: `${authState.user.name} has added a comment to your request "${request.title}"`,
          type: "new_comment" as const,
          read: false,
          createdAt: new Date().toISOString(),
          requestId,
        };
        notificationDispatch({
          type: "ADD_NOTIFICATION",
          payload: notification,
        });
      } else if (authState.user.role === "AGENT" && request.adminId) {
        // Notify admin
        const notification = {
          id: crypto.randomUUID(),
          userId: request.adminId,
          title: "New Comment Added",
          message: `${authState.user.name} has added a comment to request "${request.title}"`,
          type: "new_comment" as const,
          read: false,
          createdAt: new Date().toISOString(),
          requestId,
        };
        notificationDispatch({
          type: "ADD_NOTIFICATION",
          payload: notification,
        });
      }

      // Clear the form
      setNewComment("");
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add comment. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  // const cancelEditNote = () => {
  //   setEditedNote(originalAnalysis?.aiResponse || "");
  //   setIsEditingNote(false);
  // };

  // const saveEditNote = async () => {
  //   const updatedRequest = {
  //     ...request,
  //     originalAnalysis: {
  //       ...request?.originalAnalysis,
  //       aiResponse: editedNote,
  //     },
  //   };
  //   setUpdatingNote(true);
  //   try {
  //     const response = await updateRequest(updatedRequest);
  //     console.log({ response });
  //     setIsEditingNote(false);
  //   } catch (error) {
  //     console.log("Error Occured in updating request", error);
  //     setEditedNote(originalAnalysis?.aiResponse || "");
  //   }
  //   setUpdatingNote(false);
  // };

  const discardNote = async () => {
    const updatedRequest = {
      ...request,
      originalAnalysis: {
        ...request?.originalAnalysis,
        aiResponse: "",
      },
    };
    setUpdatingNote(true);
    try {
      const response = await updateRequest(updatedRequest);
      console.log({ response });
    } catch (error) {
      console.log("Error Occured in discarding note", error);
      setEditedNote(originalAnalysis?.aiResponse || "");
    }
    setUpdatingNote(false);
  };

  const releaseNote = async () => {
    if ((!editedNote.trim() && selectedFiles.length === 0) || !authState.user) {
      return;
    }

    setError(null);
    setUpdatingNote(true);

    try {
      // Upload any attached files
      const attachments =
        selectedFiles.length > 0 ? await uploadFiles(selectedFiles) : undefined;

      // Create the comment
      const comment: RequestComment = {
        userId: authState.user.id,
        id: crypto.randomUUID(),
        requestId,
        content: editedNote.trim(),
        author: authState.user.name,
        attachments,
        createdAt: new Date().toISOString(),
      };

      // Find the request
      let request = requestState.requests.find((r) => r.id === requestId);
      if (!request) {
        throw new Error("Request not found");
      }
      if (request?.status === "Pending") {
        request = {
          ...request,
          status: "In Progress",
        } as Request;
        await updateRequest(request);
      }
      // Add the comment
      dispatch({
        type: "RELEASE_NOTE",
        payload: { requestId, comment },
      });

      setSelectedFiles([]);
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add comment. Please try again.");
    } finally {
      setUpdatingNote(false);
    }
  };

  const isAiAssistance = originalAnalysis?.category === "AI Assistant";
  const isAdmin =
    authState.user?.role === "ADMIN" || authState.user?.role === "SUPER_ADMIN";
  const isResponseEditable =
    !isAiAssistance && originalAnalysis?.aiResponse && isAdmin;

  return (
    <div className="mt-6 space-y-4">
      <div className="flex items-center space-x-2">
        <MessageSquare className="h-5 w-5 text-gray-400" />
        <h3 className="text-sm font-medium text-gray-900">Processing Notes</h3>
      </div>
      {isAiAssistance && originalAnalysis?.aiResponse && (
        <>
          {/* <div className={`rounded-lg p-4 bg-blue-50`}>
            <div className="flex justify-between">
              <span className={`text-sm font-medium text-blue-900 `}>
                {"Victoria AI"}
              </span>
            </div>
            <p className={`mt-1 text-sm text-blue-800 `}>
              {originalAnalysis?.aiResponse}
            </p>
          </div> */}
          <div className="rounded-lg p-4 bg-blue-50">
            <div className="flex justify-between mb-1">
              <span className={`text-sm font-medium text-blue-900 `}>
                {"Victoria AI"}
              </span>
            </div>
            <TextArea
              className={clsx(
                "rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm text-blue-800 "
              )}
              value={originalAnalysis?.aiResponse}
              autoSize={true}
            />
          </div>
        </>
      )}

      <div className="space-y-4">
        {/* {!isAiAssistance && originalAnalysis?.aiResponse && (
          <>
            <div className={` bg-gray-50 p-4`}>
            <span className="text-sm font-medium text-gray-900">
                AI response
              </span>
              <TextArea
                className={clsx(
                  "rounded-lg px-0 mt-1 text-sm text-gray-700 !bg-transparent w-full resize-none !shadow-none border-none",
                  isEditingNote ? "pointer-events-auto" : "pointer-events-none"
                )}
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
                autoSize={true}
                disabled={updatingNote}
              />
              {isAdmin && (
                <div className="flex justify-end gap-3">
                  {isEditingNote ? (
                    <>
                      {" "}
                      <Button variant="outline" onClick={cancelEditNote} disabled={updatingNote}>
                        Cancel
                      </Button>
                      <Button onClick={saveEditNote} disabled={updatingNote}>Save</Button>
                    </>
                  ) : (
                    <Button onClick={() => setIsEditingNote(true)} disabled={updatingNote}>Edit</Button>
                  )}
                </div>
              )}
            </div>
          </>
        )} */}
        {comments.map((comment) => (
          <div key={comment.id} className={`rounded-lg p-4 bg-gray-50`}>
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-900">
                {authState?.user?.id === comment.userId
                  ? authState.user.name
                  : comment.author}
              </span>
              <span className="text-sm text-gray-500">
                {new Date(comment.createdAt).toLocaleString()}
              </span>
            </div>
            {/* <p className={`mt-1 text-sm text-gray-700`}>{comment.content}</p> */}
            <TextArea
              className={clsx(
                "rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm"
              )}
              value={comment.content}
              autoSize={true}
            />

            {comment.attachments && comment.attachments.length > 0 && (
              <div className="mt-3 space-y-2">
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <Paperclip className="h-4 w-4" />
                  <span>Attachments:</span>
                </div>
                <ul className="space-y-2">
                  {comment.attachments.map((file) => (
                    <li
                      key={file.id}
                      className="flex items-center justify-between rounded-md bg-white p-2 text-sm"
                    >
                      <div className="flex items-center space-x-2">
                        <span className="font-medium">{file.name}</span>
                        <span className="text-gray-500">
                          ({(file.size / 1024).toFixed(1)} KB)
                        </span>
                      </div>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          download(file.url, file.name);
                        }}
                        className="text-blue-600 hover:text-blue-700 cursor-pointer"
                      >
                        <Download className="h-4 w-4" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-4 space-y-4">
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}
        {isResponseEditable ? (
          // <textarea
          //   value={editedNote}
          //   onChange={(e) => setEditedNote(e.target.value)}
          //   placeholder="Add a note about the progress..."
          //   className="px-3 pt-3 pb-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          //   rows={3}
          //   disabled={isUploading}
          // />
          <div className="p-3 border border-gray-200 rounded-lg">
            <TextArea
              className={clsx(
                "rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm"
              )}
              placeholder="Add a note about the progress..."
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              autoSize={true}
              disabled={updatingNote}
            />
          </div>
        ) : (
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a note about the progress..."
            className="px-3 pt-3 pb-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            rows={3}
            disabled={isUploading}
          />
        )}

        {/* <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a note about the progress..."
          className="px-3 pt-3 pb-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          rows={3}
        /> */}

        <FileUpload
          onFileSelect={handleFileSelect}
          onRemove={handleFileRemove}
          selectedFiles={selectedFiles}
          accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
          maxSize={5 * 1024 * 1024} // 5MB
        />

        {isResponseEditable ? (
          <div className="flex justify-end gap-2">
            <Button onClick={discardNote} disabled={updatingNote || (authState.user?.role === "ADMIN" && request?.adminId === null)}>
              Discard Note
            </Button>
            <Button
              onClick={releaseNote}
              disabled={
                (authState.user?.role === "ADMIN" && request?.adminId === null) ||
                updatingNote ||
                (!editedNote.trim() && selectedFiles.length === 0)
              }
            >
              Approve
            </Button>
          </div>
        ) : (
          <div className="flex justify-end">
            <Button
              onClick={handleAddComment}
              disabled={
                (!newComment.trim() && selectedFiles.length === 0) ||
                !authState.user ||
                isGeneratingResponse ||
                isUploading
              }
            >
              {isUploading
                ? "Uploading..."
                : isGeneratingResponse
                ? "Generating Response..."
                : "Add Note"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
