import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { User } from "@/lib/types";
import { Category } from "@/lib/categories";
import { useAuth } from "./AuthContext";

interface AdminState {
  offices: any;
  categories: any;
  users: User[];
  loading: boolean;
  error: string | null;
  assosicatedAdmins : User[] | null 
}

type AdminAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "SET_OFFICES"; payload: any }
  | { type: "SET_CATEGORIES"; payload: any }
  | { type: "DELETE_CATEGORY"; payload: string }
  | { type: "UPDATE_CATEGORY"; payload: any }
  | { type: "SET_USERS"; payload: User[] }
  | { type: "ADD_USER"; payload: User }
  | { type: "UPDATE_USER"; payload: User }
  | { type: "ADD_OFFICE"; payload: any }
  | { type: "UPDATE_OFFICE"; payload: any }
  | { type: "DELETE_OFFICE"; payload: string }
  | { type: "SET_ASSOSIATED_ADMINS"; payload: User[] };

const initialState: AdminState = {
  offices: [],
  categories: [],
  users: [],
  loading: true,
  error: null,
  assosicatedAdmins : []
};

const AdminContext = createContext<{
  state: AdminState;
  dispatch: React.Dispatch<AdminAction>;
} | null>(null);

function adminReducer(state: AdminState, action: AdminAction): AdminState {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload, error: null };

    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };

    case "SET_OFFICES":
      return { ...state, offices: action.payload, loading: false };

    case "SET_CATEGORIES":
      return { ...state, categories: action.payload, loading: false };

    case "UPDATE_CATEGORY":
      const updatedCategory = action.payload;
      const categoryRef = doc(db, "categories", updatedCategory.id);
      (async () => {
        try {
          await updateDoc(categoryRef, {
            name: updatedCategory.name,
            description: updatedCategory.description,
          });
          return {
            ...state,
            categories: state.categories.map((cat: any) =>
              cat.id === updatedCategory.id ? updatedCategory : cat
            ),
            loading: false,
          };
        } catch (error) {
          console.error("Error updating category in Firestore:", error);
          return {
            ...state,
            loading: false,
            error: "Failed to update category",
          };
        }
      })();
      return state;
    case "DELETE_CATEGORY":
      (async () => {
        try {
          await deleteDoc(doc(db, "categories", action.payload));
        } catch (error) {
          console.error("Error deleting category from Firebase:", error);
        }
      })();
      return {
        ...state,
        categories: state.categories.filter(
          (category: Category) => category.id !== action.payload
        ),
      };
    case "SET_USERS":
      return { ...state, users: action.payload, loading: false };

    case "ADD_USER":
      return { ...state, users: [...state.users, action.payload] };

    case "UPDATE_USER":
      return {
        ...state,
        users: state.users.map((user) => {
          return user.id === action.payload.id ? action.payload : user;
        }),
      };


    case "ADD_OFFICE":
      return { ...state, offices: [...state.offices, action.payload] };

    case "UPDATE_OFFICE":
      return {
        ...state,
        offices: state.offices.map((office: any) =>
          office.id === action.payload.id ? action.payload : office
        ),
      };

    case "DELETE_OFFICE":
      return {
        ...state,
        offices: state.offices.filter(
          (office: any) => office.id !== action.payload
        ),
      };

    case "SET_ASSOSIATED_ADMINS" : {
      return {
        ...state,
        assosicatedAdmins : action.payload
      }
    }

    default:
      return state;
  }
}

export function AdminProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(adminReducer, initialState);
  const { state: authState } = useAuth();
  useEffect(() => {
    const loadAdminData = async () => {
      dispatch({ type: "SET_LOADING", payload: true });

      try {
        let officeQuery: any;
        if (authState?.user?.role === "SUPER_ADMIN") {
          officeQuery = query(
            collection(db, "offices"),
            where("workspaceId", "==", authState.user?.workspaceId)
          );
        } else {
          officeQuery = query(
            collection(db, "offices"),
            where("id", "in", authState?.user?.officeIds || "")
          );
        }
        const unsubOffices = onSnapshot(
          officeQuery,
          (snapshot: any) => {
            const offices = snapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
            })) as any;
            dispatch({ type: "SET_OFFICES", payload: offices });
          },
          (error) => {
            console.error("Error loading offices:", error);
            if (error.code !== "permission-denied") {
              dispatch({
                type: "SET_ERROR",
                payload: "Failed to load offices",
              });
            }
          }
        );
        const unsubCategories = onSnapshot(
          query(
            collection(db, "categories"),
            where("workspaceId", "==", authState.user?.workspaceId)
          ),
          (snapshot) => {
            const categories = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              default: doc?.data()?.default || false,
            })) as Category[];

            dispatch({ type: "SET_CATEGORIES", payload: categories });
          },
          (error) => {
            console.error("Error loading categories:", error);
            if (error.code !== "permission-denied") {
              dispatch({
                type: "SET_ERROR",
                payload: "Failed to load categories",
              });
            }
          }
        );

        return () => {
          unsubOffices();
          unsubCategories();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
      }
    };

    if (authState?.user?.id) {
      loadAdminData();
    }
  }, [authState?.user]);

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: "SET_LOADING", payload: true });

      try {
        let queryCondition: any;

        if (authState?.user?.role === "SUPER_ADMIN") {
          queryCondition = query(
            collection(db, "users"),
            where("workspaceId", "==", authState.user?.workspaceId),
            where("id", "!=", authState?.user?.id)
          );
        } else {
          queryCondition = query(
            collection(db, "users"),
            where(
              "officeIds",
              "array-contains-any",
              authState?.user?.officeIds
            ),
            where("role", "==", "AGENT"),
            where("id", "!=", authState?.user?.id)
          );
        }
        const unsubUsers = onSnapshot(
          queryCondition,
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_USERS", payload: users });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    const loadAdmins = () => {
      const unsubAdmins = onSnapshot(
        query(
          collection(db, "users"),
          where("role", "!=", "AGENT"),
          where("workspaceId", "==", authState.user?.workspaceId)
        ),
        (snapshot) => {
          const admins = (snapshot.docs || []).map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as User[];
          dispatch({ type: "SET_ASSOSIATED_ADMINS", payload: admins})
        },
        (error) => {
          console.error("Error loading admins:", error);
          if (error.code !== "permission-denied") {
            dispatch({ type: "SET_ERROR", payload: "Failed to load admins" });
          }
        }
      );

      return () => {
        unsubAdmins();
      };
    };

    if (authState?.user?.id) {
      loadAdmins();
    }

    if (
      authState?.user?.id
    ) {
      loadData();
    }
  }, [authState?.user, state?.offices, state?.categories]);

  return (
    <AdminContext.Provider value={{ state, dispatch }}>
      {children}
    </AdminContext.Provider>
  );
}

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
}
