import { initializeApp, getApps, getApp } from 'firebase/app';
import { 
  getAuth, 
  setPersistence, 
  browserLocalPersistence,
  onAuthStateChanged,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { 
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { firebaseConfig } from './config';

// Initialize Firebase only if no apps exist
export const app = getApps().length ? getApp() : initializeApp(firebaseConfig);

// Initialize Auth with persistence
export const auth = getAuth(app);

// Initialize Firestore with singleton pattern and persistence
let firestoreInstance: ReturnType<typeof getFirestore> | null = null;

export const db = (() => {
  if (!firestoreInstance) {
    try {
      // Initialize Firestore with persistence and offline support
      firestoreInstance = initializeFirestore(app, {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
          cacheSizeBytes: CACHE_SIZE_UNLIMITED
        })
      });
    } catch (error) {
      console.error('Error initializing Firestore:', error);
      // Fallback to regular Firestore
      firestoreInstance = getFirestore(app);
    }
  }
  return firestoreInstance;
})();

// Initialize Storage
export const storage = getStorage(app);

// Initialize auth persistence
setPersistence(auth, browserLocalPersistence).catch(console.error);

// Set up auth state listener
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user.email);
  } else {
    console.log('User is signed out');
  }
});

// Test Firebase connection
export async function testFirebaseConnection(): Promise<boolean> {
  try {
    // Test auth by trying to sign in with test credentials
    await signInWithEmailAndPassword(auth, 'tyler@joinvictory.com', 'Victory101$');
    return true;
  } catch (error: any) {
    // If we get an auth/wrong-password error, the connection is working
    // but credentials are wrong, which is fine for testing connection
    if (error.code === 'auth/wrong-password' || 
        error.code === 'auth/invalid-credential' ||
        error.code === 'auth/user-not-found') {
      return true;
    }
    console.warn('Connection test failed:', error);
    return false;
  }
}