import { FolderCog } from 'lucide-react';

export default function EmptyState() {
  return (
    <div className="rounded-lg bg-white p-8 text-center">
      <FolderCog className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No categories</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new category.</p>
    </div>
  );
}