import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import Footer from "./Footer";
import Header from "./Header";

interface RequireAuthProps {
  children: ReactNode;
}

export default function RequireAuth({ children }: RequireAuthProps) {
  const { state } = useAuth();

  const location = useLocation();

  if (state?.loading) {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <span
          className="authentication-loader
authentication-loader scale-150"
        ></span>
      </div>
    );
  }

  if (!state.isAuthenticated || !state.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (state.user.accountStatus === "PENDING") {
    return (
      <div className="flex flex-1 flex-col overflow-hidden bg-[#0A0B1A] relative">
        <div className="relative isolate">
          <div className="absolute inset-x-0 -top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4169E1] to-[#9370DB] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
          </div>
          <Header />
          <main
            className={`flex justify-center items-center h-[calc(100vh-65px)] flex-col px-4`}
          >
            <div className="flex justify-center -mt-16 mb-3 lg:mb-8">
              <img
                src="/images/victoria.png"
                alt="Victoria"
                className="h-32 w-auto"
                style={{
                  objectFit: "contain",
                  filter: "drop-shadow(0 0 20px rgba(65, 105, 225, 0.3))",
                }}
              />
            </div>
            <h1 className="text-4xl lg:text-6xl font-bold text-white text-center mb-6 lg:mb-8">
              Your account is pending for approval.
            </h1>

            <p className="mt-2 text-2xl sm:text-3xl leading-relaxed text-gray-300 max-w-4xl mx-auto text-center">
              Your account is currently under review by an administrator. Please
              contact the admin if you have any questions or need assistance
              with the approval process.
              <span className="mt-4 block">Thank you for your patience!</span>
            </p>
          </main>
          <Footer />
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
