import { useState, useEffect } from "react";
import { Mic, Square } from "lucide-react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Button from "./ui/Button";
import { cn, extractFileName, generateId } from "@/lib/utils";
import { useReactMediaRecorder } from "react-media-recorder";
import { AssemblyAI } from "assemblyai";
import { audioStorage } from "@/lib/storage/audio";
import { useAuth } from "@/context/AuthContext";
import { getStorage, ref, deleteObject } from "firebase/storage";

interface VoiceRecorderProps {
  onRecordingComplete: (data: {
    transcript: string;
    whisperData?: {
      confidence: number;
      segments?: Array<{ text: string; confidence: number }>;
    };
    uploadUrl: string;
    isAiCategory?: boolean;
  }) => void;
  setIsAnalyzing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function VoiceRecorder({
  onRecordingComplete,
  setIsAnalyzing,
}: VoiceRecorderProps) {
  const [isListening, setIsListening] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [ai, setAi] = useState(false);
  const [string, setString] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const client = new AssemblyAI({
    apiKey: "accfc177353e4c19b49f001b4dc1c763",
  });
  const { state } = useAuth();

  const commands = [
    {
      command: ["victoria", "Victoria"],
      callback: () => startVoiceTrigger(),
      matchInterim: true,
    },
  ];

  const deleteFile = async ({ urlRef }: { urlRef: string }) => {
    try {
      const fileName = extractFileName(urlRef);
      if (!fileName) {
        console.error("Could not extract file name");
        return;
      }
      console.log("File Name:", fileName);

      const storage = getStorage();
      const desertRef = ref(storage, `audio/${state?.user?.id}/${fileName}`);
      await deleteObject(desertRef);
      setUploadUrl("");
      setString("");
      console.log("Perform deletion");
      return;
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const startVoiceTrigger = async() => {
    console.log("Starting audio trigger");
    setAi(true);
    setIsRecorded(true);
    if(uploadUrl){
     await deleteFile({urlRef: uploadUrl});
     handleRecording();
    }else{
      handleRecording();
    }
  };

  const { transcript, resetTranscript, browserSupportsSpeechRecognition, listening,isMicrophoneAvailable} =
    useSpeechRecognition({ commands });
    
  useEffect(() => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous:true,language: "en-US" });
    }
    return () => {
      SpeechRecognition.stopListening();
    };
  }, [browserSupportsSpeechRecognition]);

  const { startRecording, stopRecording } = useReactMediaRecorder({
    video: false,
    audio: true,
    blobPropertyBag: { type: "audio/webm" },
    onStop(_, blob) {
      uploadFile(blob);
    },
  });

  const uploadFile = async (file: any) => {
    try {
      setIsAnalyzing(true);
      if (uploadUrl === "") {
        const audioUrl = await audioStorage.upload(
          file,
          `${state?.user?.id}/${generateId()}.webm`
        );
        console.log("Perform upload without delete");

        setUploadUrl(audioUrl);
      } else {
        await deleteFile({ urlRef: uploadUrl });
        const audioUrl = await audioStorage.upload(
          file,
          `${state?.user?.id}/${generateId()}.webm`
        );
        console.log("Perform upload after delete");

        setUploadUrl(audioUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRecording = async () => {
    await SpeechRecognition.stopListening();
    resetTranscript();
    setIsListening(true);
    startRecording();
  };

  const stopRecording1 = async () => {
    setString(transcript);
    setIsListening(false);
    stopRecording();
  };

  const loadTranscription = async () => {
    try {
      const transcript = await client.transcripts.transcribe({
        audio: uploadUrl,
      });
      const string = transcript?.text as string;
      setString(string);
      onRecordingComplete({
        transcript: string?.trim(),
        whisperData: {
          confidence: 0.95,
          segments: [
            {
              text: string?.trim(),
              confidence: 0.95,
            },
          ],
        },
        uploadUrl,
        isAiCategory: ai,
      });
      setIsRecorded(false)
      setTimeout(async () => {
        await SpeechRecognition.startListening({
          language: "en-US",
          continuous:true
        });
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (uploadUrl&& string==="") {
      loadTranscription();
    }
  }, [uploadUrl]);

  console.log({
    isListening,
    ai,
    string,
    transcript,
    uploadUrl,
  });

  const content = isListening
    ? "Recording in progress..."
    : '<p>Click the mic to start recording.<br> To activate AI, start your request by saying "Victoria".</p>';

  return (
    <div className="flex flex-col space-y-4 rounded-lg border border-gray-200 bg-white p-4">
      <div className="flex items-center justify-center space-x-4">
        <Button
          type="button"
          onClick={() => {
            if (isListening) {
              stopRecording1();
            } else {
              setAi(false);
              handleRecording();
            }
          }}
          variant={isListening ? "outline" : "primary"}
          className={cn("w-12 group h-12 rounded-full p-0 relative", {
            "animate-pulse": isListening,
          })}
          aria-label={isListening ? "Stop recording" : "Start recording"}
        >
          {isListening ? (
            <Square className="h-6 w-6" />
          ) : (
            <Mic className="h-6 w-6" />
          )}
        </Button>
        {isListening && (
          <div className="flex items-center space-x-2">
            <div className="h-2 w-2 rounded-full bg-red-500 animate-pulse" />
            <span className="text-sm font-medium">Listening...</span>
          </div>
        )}
      </div>

      {string && (
        <div className="mt-2 rounded-md bg-gray-50 p-3">
          <p className="text-sm text-gray-700">{string}</p>
        </div>
      )}

      <p dangerouslySetInnerHTML={{
          __html: content  }} className="text-center text-sm text-gray-500"/>
    </div>
  );
}
