// Simulated AI response for development
import { openai } from "./openai/client";

export async function generateAIResponse(transcript: string): Promise<string> {
  return `Thank you for your request. I understand you're asking about: "${transcript}". 
  As this is a development environment, I'm providing a simulated response. In production, 
  this would connect to the OpenAI API for real responses.`;
}

export async function analyzeTranscript(
  transcript: string,
  categories: any
): Promise<{
  title: string;
  category: string;
  aiResponse: string;
}> {
  const { title, category, aiResponse } = await analyzeText(
    transcript,
    categories
  );
  return {
    title: title,
    category: category,
    aiResponse: aiResponse,
  };
}

const analyzeText = async (
  text: string,
  categories: any
): Promise<{ category: string; aiResponse: string; title: string }> => {
  const categoryNames = categories
    .map((category: any) => category.name)
    .join(", ");

  const prompt = `
Please do the following for the given text:

1. **Title**: Provide a short title summarizing the key points of the text.
2. **Response**: Provide the AI's response to the query within the text, based on the content provided.
3. **Category**: Classify the text into one of the following categories: ${categoryNames}

Text: "${text}"

Please format your response like this:
Title: [title]
Response: [reponse]
Category: [category]
`;

  try {
    const response = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You are an AI that generates a title, reponse, and category for text.",
        },
        { role: "user", content: prompt },
      ],
      model: "gpt-4",
      max_tokens: 200,
    });

    if (response?.choices[0]?.message?.content) {
      const result = response.choices[0].message.content.trim();

      const sections = result.split(/(?=(Title:|Response:|Category:))/g);

      let title = "No title provided";
      let aiResponse = "No Ai response provided";
      let category = "Uncategorized";

      sections.forEach((section: any) => {
        if (section.startsWith("Title:")) {
          title = section.replace("Title:", "").trim();
        } else if (section.startsWith("Response:")) {
          aiResponse = section.replace("Response:", "").trim();
        } else if (section.startsWith("Category:")) {
          category = section.replace("Category:", "").trim();
        }
      });

      return {
        title,
        aiResponse,
        category,
      };
    }
    return {
      title: "No title provided",
      aiResponse: "No Ai response provided",
      category: "Uncategorized",
    };
  } catch (error) {
    console.error("Error classifying text:", error);
    throw new Error("Error while calling OpenAI for text classification");
  }
};
