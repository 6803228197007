import { getStorage } from 'firebase/storage';
import { app } from './app';

// Initialize Firebase Storage
const storage = getStorage(app);

// Configure CORS settings from cors.json (this will need to be done via Firebase CLI)
if (storage) {
  // CORS logic here can be done in the Firebase Console or using the Firebase CLI.
  // The `storage.customDomain` setting isn't valid here.

}

export { storage };
