import { collection, doc, setDoc, getDocs, writeBatch, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase/app';
import { CATEGORIES } from './categories';

async function verifyDataExists() {
  try {
    // Check offices
    const officesSnapshot = await getDocs(collection(db, 'offices'));
    const hasOffices = !officesSnapshot.empty;

    // Check categories
    const categoriesSnapshot = await getDocs(collection(db, 'categories'));
    const hasCategories = !categoriesSnapshot.empty;

    // Check super admin
    const superAdminSnapshot = await getDocs(collection(db, 'users'));
    const hasSuperAdmin = superAdminSnapshot.docs.some(
      doc => doc.data().email === 'tyler@joinvictory.com'
    );

    return hasOffices && hasCategories && hasSuperAdmin;
  } catch (error) {
    console.error('Error verifying data:', error);
    return false;
  }
}

export async function initializeFirestore() {
  try {
    // Check if data already exists
    const hasData = await verifyDataExists();
    if (hasData) {
      console.log('Data already initialized');
      return;
    }

    console.log('Initializing data...');

    // Initialize default offices
    const offices = [
      {
        id: 'beavercreek',
        name: 'Beavercreek Office',
        address: '123 Main St, Beavercreek, OH',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      },
      {
        id: 'liberty',
        name: 'Liberty Office',
        address: '456 Oak St, Liberty, OH',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }
    ];

    // Use a batch write for atomic operations
    const batch = writeBatch(db);

    // Add offices
    offices.forEach(office => {
      const officeRef = doc(db, 'offices', office.id);
      batch.set(officeRef, office, { merge: true });
    });

    // Add categories
    Object.entries(CATEGORIES).forEach(([key, name]) => {
      const id = key.toLowerCase().replace(/\s+/g, '-');
      const categoryRef = doc(db, 'categories', id);
      batch.set(categoryRef, {
        id,
        name,
        description: `Requests related to ${name.toLowerCase()}`,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }, { merge: true });
    });

    // Commit the batch
    await batch.commit();
    console.log('Successfully initialized Firestore with default data');

    // Verify data was properly initialized
    const verificationResult = await verifyDataExists();
    if (!verificationResult) {
      throw new Error('Data verification failed after initialization');
    }

  } catch (error) {
    console.error('Error initializing Firestore:', error);
    throw error;
  }
}