import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";

interface RequireAuthProps {
  children: ReactNode;
}

export default function NoAuth({ children }: RequireAuthProps) {
  const { state } = useAuth();

  const location = useLocation();
  if (state?.loading) {
    return (
      <div className="h-screen w-full flex justify-center items-center">
        <span
          className="authentication-loader
authentication-loader scale-150"
        ></span>
      </div>
    );
  }

  if (state.isAuthenticated || state.user) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
