import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
  useState,
} from "react";
import { User } from "@/lib/types";
import { signIn, signOut, getCurrentUser } from "@/lib/firebase";
import { storage } from "@/lib/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "@/lib/firebase/app";
import { doc, getDoc, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  token:string
}

type AuthAction =
  | { type: "LOGIN"; payload: User }
  | { type: "LOGOUT" }
  | { type: "UPDATE_USER"; payload: User }
  | { type: "SET_LOADING" }
  | { type: "SET_ERROR"; payload: string };

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  loading: true,
  error: null,
  token:''
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: {
          ...action.payload,
          accountStatus: action?.payload?.accountStatus || "ACTIVE",
        },
        isAuthenticated: true,
        loading: false,
        error: null,
      };

    case "LOGOUT":
      return {
        ...initialState,
        loading: false,
      };

      case "UPDATE_USER":
        return {
          ...state,
          user: state.user ? { ...state.user, ...action.payload } : null,
        };

    case "SET_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

interface AuthContextType {
  state: AuthState;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (user: User) => Promise<void>;
  insertUser: (user: User) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  getToken: () => Promise<string | undefined>;
  dispatch: React.Dispatch<AuthAction>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Get latest user data from Firestore
          const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const user = {
              id: userDoc.id,
              ...userData,
              emailVerified: firebaseUser.emailVerified,
            } as User;

            dispatch({
              type: "LOGIN",
              payload: user,
            });
            navigate(location?.pathname, { replace: true });
          } else {
            console.log("No user data found in Firestore");
            dispatch({ type: "LOGOUT" });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch({ type: "LOGOUT" });
        }
      } else {
        dispatch({ type: "LOGOUT" });
      }
    });

    return () => unsubscribe();
  }, []);


  const getToken = async ()=>{
    const auth = getAuth();
    const token = await auth?.currentUser?.getIdToken();
    return token
  }

  const login = async (email: string, password: string) => {
    try {
      const user = await signIn(email, password);
      dispatch({ type: "LOGIN", payload: user });
    } catch (error) {
      console.error("Login error:", error);
      dispatch({
        type: "SET_ERROR",
        payload: error instanceof Error ? error.message : "Failed to sign in",
      });
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut();
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const updateUser = async (user: User) => {
    try {
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, user as any);
      console.log("User updated successfully in Firestore");
    } catch (error) {
      console.error("Error updating user data in Firestore:", error);
      dispatch({
        type: "SET_ERROR",
        payload: "Error updating user data. Please try again later.",
      });
    }
  };

  const insertUser = async (user: User) => {
    try {
      const userRef = doc(db, "users", user.id);
      await setDoc(userRef, user);
      console.log("User inserted successfully into Firestore");
    } catch (error: any) {
      console.error("Error inserting user data into Firestore:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error inserting user data. Please try again later.",
      });
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      const userRef = doc(db, "users", userId);
      await deleteDoc(userRef);
      console.log("User deleted successfully from Firestore");
    } catch (error: any) {
      console.error("Error deleting user data from Firestore:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error deleting user data. Please try again later.",
      });
    }
  };
  
  return (
    <AuthContext.Provider
      value={{ state, login, logout, updateUser, insertUser, deleteUser, getToken, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
