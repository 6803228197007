import { useState, useEffect } from "react";
import {
  Building2,
  Home,
  MessageSquare,
  Settings,
  Users,
  FolderCog,
  Building,
  UserCog,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { Link, NavLink } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";
import Button from "@/components/ui/Button";
import { logoStorage } from "@/lib/logo";
import { isOwner } from "./OwnerRequired";

export default function Sidebar() {
  const { state } = useAuth();
  const user = state.user;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [logos, setLogos] = useState({
    icon: "/images/logo-icon.png",
    full: "/images/logo-full.png",
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    if (screenWidth <= 786) {
      setIsCollapsed(false);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const loadLogos = async () => {
      try {
        const currentLogos = await logoStorage.get();
        setLogos({
          icon: currentLogos.icon || "/images/logo-icon.png",
          full: currentLogos.full || "/images/logo-full.png",
        });
      } catch (error) {
        console.warn("Failed to load logos:", error);
      }
    };

    loadLogos();
  }, []);

  const resizeForMobile = () => {
    if (screenWidth <= 786) {
      setIsCollapsed(false);
    }
  };

  if (!user) return null;

  // Define navigation items based on user role
  let navigationItems = [{ name: "Dashboard", href: "/dashboard", icon: Home }];

  // Agent-specific navigation
  if (user.role === "AGENT") {
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
    ];
  }

  // Admin navigation
  else if (user.role === "ADMIN") {
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Users", href: "/dashboard/admin/users", icon: UserCog },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
    ];
  }

  // Super Admin navigation
  else if (user.role === "SUPER_ADMIN") {
    if (isOwner(user.email)) {
      navigationItems = [
        ...navigationItems,
        { name: "Approvals", href: "/dashboard/admin/approvals", icon: Users },
      ];
    }
    navigationItems = [
      ...navigationItems,
      { name: "Requests", href: "/dashboard/requests", icon: MessageSquare },
      { name: "Offices", href: "/dashboard/admin/offices", icon: Building },
      {
        name: "Categories",
        href: "/dashboard/admin/categories",
        icon: FolderCog,
      },
      { name: "Users", href: "/dashboard/admin/users", icon: UserCog },
      { name: "Settings", href: "/dashboard/settings", icon: Settings },
    ];
  } 

  return (
    <div
      className={cn(
        "flex flex-col bg-gray-900 transition-all duration-300 ease-in-out ",
        isCollapsed ? "w-64 lg:w-16" : `w-16 lg:w-64`
      )}
    >
      <div
        className={cn(
          "flex h-16 items-center border-b border-gray-800 px-4",
          isCollapsed
            ? "justify-between lg:justify-center"
            : "justify-center lg:justify-between"
        )}
      >
        <div className="flex items-center text-white font-bold text-base">
          <Link
            to="/dashboard"
            className="flex items-center text-white font-bold text-base"
          >
            <img
              src="/images/logo-icon.png"
              alt="Victoria"
              className="h-8 w-8 block lg:hidden"
            />
            <img
              src="/images/logo-full.png"
              alt="Victoria"
              className={`pr-3 hidden lg:block  `}
            />
          </Link>
        </div>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="text-gray-400 hover:text-white md:hover:text-black hover:bg-[#111827] md:hover:bg-white"
        >
          {isCollapsed ? (
            <>
              <ChevronRight className="h-5 w-5 lg:block hidden" />
              <ChevronLeft className="h-5 w-5 lg:hidden" />
            </>
          ) : (
            <>
              <ChevronLeft className="h-5 w-5 lg:block hidden" />
              <ChevronRight className="h-5 w-5 lg:hidden " />
            </>
          )}
        </Button>
      </div>
      <nav className="flex-1 space-y-1 px-2 py-4">
        {navigationItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            onClick={() => resizeForMobile()}
            className={({ isActive }) =>
              cn(
                "group flex items-center rounded-md px-2 py-2 text-sm font-medium transition-colors",
                isActive
                  ? "bg-gray-800 text-white"
                  : "transition-colors text-gray-300 hover:bg-gray-700 hover:text-white",
                isCollapsed
                  ? "justify-start lg:justify-center"
                  : "justify-center lg:justify-start"
              )
            }
            title={isCollapsed ? item.name : undefined}
          >
            <item.icon
              className={cn(
                "flex-shrink-0",
                isCollapsed ? "h-6 w-6  lg:mr-0 mr-3" : " h-5 w-5 mr-0 lg:mr-3"
              )}
              aria-hidden="true"
            />
            <span
              className={cn(!isCollapsed ? "lg:block hidden" : "lg:hidden")}
            >
              {item.name}
            </span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
}
