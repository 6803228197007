import { APP_VERSION } from "@/lib/version";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-400 text-sm py-2 px-4 fixed bottom-0 left-0 right-0">
      <div className="flex justify-between items-center">
        <span>© {new Date().getFullYear()} Victoria</span>
        <div>
          <Link to='/privacy-policy' className="mr-4">Privacy Policy</Link>
          <span>v{APP_VERSION}</span>
        </div>
      </div>
    </footer>
  );
}
