const createUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/createUser";
const deleteUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/deleteUser";

export const createUser = async ({
  token,
  body,
}: {
  token: string;
  body: Record<string, any>;
}) => {
  return await fetch(createUserEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
};

export const deleteUser = async ({
  id,
  token,
}: any) => {
  try {
    const response = await fetch(deleteUserEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
    console.log("Response from Firebase Function:", result);
    return result;
  } catch (error) {
    console.log({ error });
  }
};
