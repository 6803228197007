import { useSearchParams } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

export default function AuthActions() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");

  return (
    <>
      {mode === "resetPassword" && <ResetPassword />}
      {mode === "verifyEmail" && <VerifyEmail />}
    </>
  );
}
