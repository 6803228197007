import UserManagement from '@/components/UserManagement';

export default function Users() {
  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">User Management</h1>
      </div>

      <div className="mt-8">
        <UserManagement />
      </div>
    </div>
  );
}