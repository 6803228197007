import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import EmailVerificationBanner from '../components/EmailVerificationBanner';
import { useAuth } from '@/context/AuthContext';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const { state: { user } } = useAuth();
  const showBanner = false;
  // const showBanner = user && !user.emailVerified;

  return (
    <div className="flex h-screen bg-gray-100">
      {showBanner && <EmailVerificationBanner />}
      <Sidebar />
      <div className="flex flex-1 flex-col overflow-hidden">
        <Header />
        <main className={`flex-1 overflow-y-auto p-4 pb-16 ${showBanner ? 'mt-16' : ''}`}>
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}