import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateRequest, useRequests } from "@/context/RequestsContext";
import { useAuth } from "@/context/AuthContext";
import { useNotifications } from "@/context/NotificationContext";
import { cn } from "@/lib/utils";
import { Filter, MessageSquare, SortDesc } from "lucide-react";
import Button from "@/components/ui/Button";
import RequestComments from "@/components/RequestComments";
import { Request } from "@/lib/types";
import { canProcessRequest } from "@/lib/permissions";
import { useAdmin } from "@/context/AdminContext";
import { Select, Space } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { CustomToast } from "@/utils/helpers";
import Modal from "@/components/ui/Modal";

export default function Requests() {
  const { state: requestState, dispatch } = useRequests();
  const { state: authState } = useAuth();
  const { state: adminState } = useAdmin();
  const { dispatch: notificationDispatch } = useNotifications();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const location = useLocation();
  const requestRefs = useRef<{ [key: string]: HTMLLIElement }>({});
  const [adminListObj, setAdminListObj] = useState<Record<string, string>>({});
  const [adminList, setAdminList] = useState<Record<string, string>[]>([]);
  const [updatingRequest, setUpdatingRequest] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);

  const navigate = useNavigate();

  const currentUser = authState?.user;

  let req = requestState?.loadedSuggestion
    ? requestState.suggestedRequests
    : requestState.requests;

  // if (currentUser?.role === "ADMIN") {
  //   const reqIds : string[] = []
  //   const allReq = [...requestState?.assignedRequest, ...requestState?.requests];
  //   req = allReq?.filter(request=>{
  //     if (request?.adminId && request?.adminId !== currentUser?.id) return false;
  //     if (reqIds?.includes(request.id)) return false;
  //     reqIds.push(request.id);
  //     return true
  //   })
  // }

  const uniqueRequests = Array.from(
    new Map(req.map((item) => [item.id, item])).values()
  );

  // Filter requests based on user role and permissions
  const filteredRequests = uniqueRequests?.filter((request) => {
    // First apply category and status filters
    if (selectedCategory && request.category !== selectedCategory) return false;
    if (selectedStatus && request.status !== selectedStatus) return false;

    const user = authState.user;
    if (!user) return false;

    // Super admin can see all requests
    if (user.role === "SUPER_ADMIN") return true;

    // Admins can see requests for their offices and categories
    if (user.role === "ADMIN") {
      return true;
    }

    // Agents can only see their own requests
    if (user.role === "AGENT") {
      return request.agentId === user.id;
    }

    return false;
  });

  // Sort requests by creation date (newest first)
  const sortedRequests = [...filteredRequests].sort(
    (a: any, b: any) =>
      new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
  );

  // Handle scrolling to specific request when navigating from notification or dashboard
  useEffect(() => {
    const scrollToId = location.state?.scrollToId;
    if (scrollToId && requestRefs.current[scrollToId]) {
      requestRefs.current[scrollToId].scrollIntoView({ behavior: "smooth" });
      // Clear the state to prevent scrolling on subsequent renders
      window.history.replaceState({}, document.title);
    }
  }, []);

  const handleStatusUpdate = async (request: Request) => {
    const newStatus =
      request.status === "Pending" ? "In Progress" : "Completed";
    const statusMessage =
      newStatus === "In Progress"
        ? "Started processing request"
        : "Marked request as completed";

    const comment = {
      userId: authState?.user?.id ?? "",
      id: crypto.randomUUID(),
      requestId: request.id,
      content: statusMessage,
      createdAt: new Date().toISOString(),
      author: authState.user?.name || "System",
    };

    // Update request status and assign admin if starting
    const updatedRequest: any = {
      ...request,
      status: newStatus,
      adminId:
        newStatus === "In Progress" ? authState.user?.id : request.adminId,
    };

    try {
      // Add the comment
      await updateRequest(updatedRequest);
      setIsModalOpen(false);
      dispatch({
        type: "ADD_COMMENT",
        payload: { requestId: request.id, comment },
      });

      // Create notification for the agent
      const notification = {
        id: crypto.randomUUID(),
        userId: request.agentId,
        title: "Request Status Updated",
        message: `Your request "${request.title}" has been ${
          newStatus === "In Progress" ? "started" : "completed"
        }`,
        type: "status_change" as const,
        read: false,
        createdAt: new Date().toISOString(),
        requestId: request.id,
      };

      notificationDispatch({ type: "ADD_NOTIFICATION", payload: notification });
    } catch (error) {
      console.error("Error updating request status:", error);
      // TODO: Add error handling UI
    }
  };

  const isAdmin =
    currentUser?.role === "SUPER_ADMIN" || currentUser?.role === "ADMIN";

  useEffect(() => {
    const admin: Record<string, string>[] = [];
    adminState?.assosicatedAdmins?.forEach((user) => {
      if (user.role !== "AGENT") {
        admin.push({ label: user.name, value: user.id });
        if (!adminListObj[user.id])
          setAdminListObj((prev) => ({ ...prev, [user.id]: user.name }));
      }
    });
    setAdminList(admin);
  }, [adminState?.users]);

  const getAdminName = (adminId: string | null) => {
    if (adminId) return adminListObj[adminId];
  };

  const assignAdmin = async ({
    requestId,
    adminId,
  }: {
    requestId: string;
    adminId: string;
  }) => {
    const currentAdminId = requestState.requests.find(
      (req) => req.id === requestId
    )?.adminId;

    if (currentAdminId === adminId) {
      return;
    }
    if (authState.user?.role === "ADMIN") {
      const confirmChange = window.confirm(
        `Are you sure you want to reassign this request to ${getAdminName(
          adminId
        )}`
      );
      if (!confirmChange) {
        return;
      }
    }
    setUpdatingRequest(true);
    const updateFirestore = async () => {
      try {
        const requestRef = doc(db, "requests", requestId);
        await updateDoc(requestRef, {
          adminId,
          updatedAt: new Date().toISOString(),
        });
        CustomToast(
          "success",
          `Request has beeen assigned to ${getAdminName(adminId)}`
        );
        dispatch({
          type: "ASSIGN_ADMIN",
          payload: { requestId, adminId },
        });
      } catch (error) {
        console.error("Failed to update Firestore:", error);
      }
    };
    await updateFirestore();
    setUpdatingRequest(false);
  };

  const handleModalOpen = (request: Request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleChangeStatus = async (request: Request, value: string) => {
    const updatedRequest: any = {
      ...request,
      status: value,
    };
    try {
      await updateRequest(updatedRequest);
    } catch (error) {
      console.error("Error updating request status:", error);
    }
  }

  return (
    <div className="mx-auto max-w-7xl overflow-x-clip">
      <div className="flex md:items-center justify-between  flex-col md:flex-row ">
        <h1 className="text-2xl font-semibold text-gray-900">All Requests</h1>
        <div className="flex items-start md:items-center gap-4 flex-col lg:gap-2 md:flex-row md:space-x-4 mt-6 lg:mt-0">
          <div className="flex md:items-center md:space-x-2">
            <Filter className="h-5 w-5 text-gray-400" />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All Categories</option>
              {Array.isArray(adminState?.categories) &&
                adminState?.categories.map((category) => (
                  <option key={category?.id} value={category?.name}>
                    {category?.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <SortDesc className="h-5 w-5 text-gray-400" />
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              <option value="Pending">Pending</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          {currentUser?.role === "AGENT" && (
            <Button
              className="flex items-center space-x-2 w-fit"
              onClick={() => {
                navigate("/dashboard/requests/new");
              }}
            >
              <MessageSquare className="h-4 w-4" />
              <span>Create New Request</span>
            </Button>
          )}
        </div>
      </div>

      <div className="mt-8">
        {sortedRequests.length === 0 ? (
          <div className="rounded-lg bg-white p-8 text-center">
            <p className="text-gray-500">
              No requests found matching your filters.
            </p>
          </div>
        ) : (
          <>
            <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul role="list" className="divide-y divide-gray-200">
                {sortedRequests.map((request, i) => (
                  <li
                    key={request.id + i}
                    ref={(el) => {
                      if (el) requestRefs.current[request.id] = el;
                    }}
                    id={request.id}
                    className="p-6"
                  >
                    <div className="flex sm:flex-row flex-col-reverse sm:items-center items-start justify-between mb-4 sm:gap-0 gap-4">
                      <div>
                        <p className="text-lg font-medium text-blue-600">
                          {request.title}
                        </p>
                        {request.originalAnalysis && (
                          <p className="text-sm text-gray-500">
                            Originally suggested:{" "}
                            {request.originalAnalysis.title}
                          </p>
                        )}
                      </div>
                      {isAdmin ? (
                        <Space
                          style={{ width: "100%", maxWidth: "150px" }}
                          direction="vertical" 
                        >
                          <Select
                             
                            className={`w-full mt-1 [&>div]:!p-0 [&>div>span>span:last-child]:!pl-[11px]
                             ${request?.status === 'Completed' ?
                               '[&>div>span>span:last-child]:text-green-700 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-green-100' :
                               request?.status === 'Pending' ?
                               '[&>div>span>span:last-child]:text-yellow-800 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-yellow-100'
                                : request?.status === 'In Progress' ? 
                               '[&>div>span>span:last-child]:text-blue-800 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-blue-100'
                               : 'text-blue-800' }
                              `}
                            placeholder="Please select role"
                            value={
                             request?.status}
                            onChange={(value) =>
                              handleChangeStatus(request, value)
                            }
                            options={
                             [
                               { value: "Pending", label: "Pending" },
                               { value: "In Progress", label: "In Progress" },
                               { value: "Completed", label: "Completed" },
                             ]
                            }
                          />
                        </Space>
                      ) : (
                        <span
                          className={cn(
                            "sm:ml-2 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
                            {
                              "bg-blue-100 text-blue-800":
                                request.status === "In Progress",
                              "bg-yellow-100 text-yellow-800":
                                request.status === "Pending",
                              "bg-green-100 text-green-800":
                                request.status === "Completed",
                            }
                          )}
                        >
                          {request.status}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center space-x-2 flex-wrap text-sm text-gray-500 mb-4">
                      <span>{request.category}</span>
                      {request.originalAnalysis && (
                        <>
                          <span>
                            • Originally: {request.originalAnalysis.category}
                          </span>
                        </>
                      )}
                      <span>
                        • {new Date(request.createdAt).toLocaleString()}
                      </span>
                    </div>

                    <div className="flex items-center space-x-2 flex-wrap text-sm text-gray-500 mb-4">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Admin :
                      </label>
                      {isAdmin ? (
                        <Space
                          style={{ width: "100%", maxWidth: "150px" }}
                          direction="vertical"
                        >
                          <Select
                            disabled={
                              updatingRequest ||
                              (isAdmin && request.status === "Completed")
                            }
                            className="w-full mt-1 placeholder:text-gray-400"
                            placeholder="Please select role"
                            // defaultValue={getAdminName(request.adminId) || ""}
                            value={
                              getAdminName(request.adminId) || "Unassigned"
                            }
                            onChange={(value) =>
                              assignAdmin({
                                requestId: request?.id,
                                adminId: value,
                              })
                            }
                            options={adminList || []}
                          />
                        </Space>
                      ) : (
                        <label htmlFor="">
                          {getAdminName(request?.adminId) || "Not assigned"}
                        </label>
                      )}
                    </div>

                    <div className="space-y-4">
                      {request.transcript && (
                        <div className="rounded-lg border border-gray-200 bg-gray-50 p-4">
                          <h3 className="text-sm font-medium text-gray-700 mb-2">
                            Transcript
                          </h3>
                          <p className="text-sm text-gray-600">
                            {request.transcript}
                          </p>
                          {request.whisperData && (
                            <p className="mt-2 text-xs text-gray-500">
                              Confidence:{" "}
                              {Math.round(request.whisperData.confidence * 100)}
                              %
                            </p>
                          )}
                        </div>
                      )}

                      {request.audioUrl && (
                        <div>
                          <h3 className="text-sm font-medium text-gray-700 mb-2">
                            Audio Recording
                          </h3>
                          <audio
                            className="w-full"
                            controls
                            src={request.audioUrl}
                          />
                        </div>
                      )}

                      <RequestComments
                        requestId={request.id}
                        comments={request.comments || []}
                        originalAnalysis={request?.originalAnalysis}
                        request={request}
                      />
                    </div>

                    {request.status !== "Completed" &&
                      (authState.user?.role === "ADMIN" ||
                        authState.user?.role === "SUPER_ADMIN") &&
                      canProcessRequest(authState.user, request) && (
                        <div className="mt-4 flex justify-end">
                          <Button
                            disabled={
                              authState.user?.role === "ADMIN" &&
                              request?.adminId === null
                            }
                            variant="outline"
                            onClick={() =>
                              request.status === "In Progress"
                                ? handleModalOpen(request)
                                : handleStatusUpdate(request)
                            }
                            className="text-gray-500"
                          >
                            {request.status === "Pending"
                              ? "Start Processing"
                              : "Mark Complete"}
                          </Button>
                        </div>
                      )}
                  </li>
                ))}
              </ul>
            </div>
            {isModalOpen && (
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={""}
              >
                <p>Are you sure you want to mark this request as completed?</p>
                <div className="flex items-center justify-end gap-3 mt-4">
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                  <Button
                    onClick={() => {
                      handleStatusUpdate(selectedRequest || ({} as Request));
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
}
