import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app } from '../firebase/app';
import { storage } from '../firebase/storage';
import { auth } from '../firebase/app';

const AUDIO_PATH = 'audio';

export const audioStorage = {
  upload: async (blob: Blob, filename: string): Promise<string> => {
    try {
      // Create a storage reference with metadata
      const audioRef = ref(storage, `${AUDIO_PATH}/${filename}`);
      
      // Upload with metadata
      const metadata = {
        contentType: blob.type,
        customMetadata: {
          uploadedBy: auth.currentUser?.uid || 'anonymous'
        }
      };

      // Upload the blob with metadata
      await uploadBytes(audioRef, blob, metadata);
      
      // Get the download URL
      const url = await getDownloadURL(audioRef);
      return url;
    } catch (error) {
      console.error('Error uploading audio:', error);
      throw new Error('Failed to upload audio recording');
    }
  }
};