import { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="bg-[#0A0B1A] text-sm text-[#d1d5db]  isolate px-6 pt-8  lg:px-8 relative">
        <div className="absolute inset-x-0 -top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <div className="relative right-0 aspect-[1155/678] w-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4169E1] to-[#9370DB] opacity-30 sm:left-1/2 sm:w-[72.1875rem]" />
        </div>
      
      <div className="container mx-auto">
        <div className="flex items-center text-white font-bold text-base">
          <Link
            to="/" 
            className="mx-auto"
          >
            <img
              src="/images/logo-full.png"
              alt="Victoria"
              className="w-72 mb-10"
            />
          </Link>
        </div>
        <h1 className="text-center text-4xl font-bold tracking-tight mb-8">
            <span className="bg-gradient-to-r from-[#4169E1] to-[#9370DB] text-transparent bg-clip-text">
            Privacy Policy
            </span>
          </h1>
        <p className="mb-2 text-sm font-bold">
          Privacy Policy for HeyVictoria.ai and Victoria AI
        </p>
        <p className="mb-2 text-sm">Effective Date: 12/12/24</p>
        <p className="mb-8 text-sm">
          Thank you for using HeyVictoria.ai and the Victoria AI application
          ("Services"). Your privacy is important to us, and we are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, and safeguard your information when you interact with
          our Services.
        </p>

        <div className="mb-8">
          <h2 className="mb-2 text-2xl font-semibold text-white">1. Information We Collect</h2>

          <p className="mb-4">We collect the following types of information:</p>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">1.1 Personal Information</h4>
            <p className="mb-3">
              When you create an account, interact with our Services, or contact
              us, we may collect:
            </p>

            <ul className="list-disc ml-4 mb-4">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Office location (if applicable)</li>
              <li>
                Payment information (for subscription services, processed
                securely by third-party providers)
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">1.2 Usage Data</h4>
            <p className="mb-3">
              We collect information about how you use our Services, including:
            </p>

            <ul className="list-disc mb-4 ml-4">
              <li>Device information (e.g., browser type, operating system)</li>
              <li>IP address</li>
              <li>Log data (e.g., pages visited, time spent on the app)</li>
              <li>
                Voice memos and associated transcriptions submitted through the
                Services
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">
              1.3 Cookies and Tracking Technologies
            </h4>
            <p className="mb-4">
              We use cookies and similar technologies to enhance user experience
              and analyze site performance. You can control cookie preferences
              through your browser settings.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-white mb-2">
            2. How We Use Your Information
          </h2>

          <p className="mb-4">
            We use the collected information for the following purposes:
          </p>

          <ul className="list-disc ml-4 mb-4">
            <li>To provide, maintain, and improve our Services</li>
            <li>To personalize your experience</li>
            <li>To process transactions and send notifications</li>
            <li>To communicate updates, offers, and support</li>
            <li>To analyze usage patterns for product development</li>
            <li>To comply with legal obligations</li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-white mb-2">
            3. Sharing Your Information
          </h2>

          <p className="mb-4">
            We do not sell your personal information. We may share your
            information with:
          </p>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">3.1 Service Providers</h4>
            <p className="mb-3">
              Third-party vendors who perform functions on our behalf, such as
              payment processing, data storage, and analytics, under strict
              confidentiality agreements.
            </p>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">3.2 Legal Compliance</h4>
            <p className="mb-3">
              Authorities or regulators as required by law to comply with legal
              obligations or to protect the rights, property, or safety of our
              users and Services.
            </p>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">3.3 Business Transfers</h4>
            <p className="mb-4">
              In the event of a merger, acquisition, or sale of assets, your
              information may be transferred to the new owner, with appropriate
              safeguards in place.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium text-white mb-4">
            4. Your Choices and Rights
          </h2>
          <div>
            <h4 className="text-lg font-medium text-white mb-1">4.1 Access and Updates</h4>
            <p className="mb-3">
              You can access, update, or delete your personal information by
              logging into your account or contacting us directly.
            </p>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">4.2 Opt-Out</h4>
            <p className="mb-3">
              You can opt out of marketing emails by clicking the "unsubscribe"
              link in our emails. Note that you cannot opt out of transactional
              or service-related communications.
            </p>
          </div>

          <div>
            <h4 className="text-lg font-medium text-white mb-1">4.3 Data Retention</h4>
            <p className="mb-3">
              We retain your information as long as necessary to fulfill the
              purposes outlined in this Privacy Policy, unless a longer
              retention period is required by law.
            </p>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-white mb-2">5. Data Security</h2>
          <p className="mb-4">
            We implement industry-standard security measures to protect your
            data. However, no online service is entirely secure. We encourage
            you to use unique, strong passwords and to report any suspicious
            activity to us immediately.
          </p>
        </div>

        <div className="pb-8">
          <h2 className="text-2xl font-semibold text-white mb-2">
            6. International Data Transfers
          </h2>
          <p className="pb-4">
            Our Services may be accessed worldwide. By using our Services, you
            consent to the transfer and processing of your information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
