import { LogOut, Search, User } from "lucide-react";
import Button from "./ui/Button";
import { useAuth } from "../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationBell from "./NotificationBell";
import { useRequests } from "@/context/RequestsContext";

const SHOW_Search = ["/dashboard","/dashboard/requests"];
export default function Header() {
  const {
    logout,
    state: { user },
  } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state: requestState, dispatch } = useRequests();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleChange = (e: any) => {
    if (e?.target?.value?.length > 0) {
      dispatch({ type: "SEARCH", payload: { value: e.target.value } });
    } else {
      dispatch({ type: "REMOVE_SEARCH" });
    }
  };

  return (
    <header
      className={`border-b relative ${
        user?.accountStatus === "PENDING" ? "border-none" : "border-gray-200"
      }`}
    >
      <div className="flex h-16 items-center justify-between px-4">
        <div className="flex flex-1">
          {user?.accountStatus === "ACTIVE" ? (
            SHOW_Search?.includes(pathname) ? (
              <div className="flex w-full max-w-lg items-center">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full mr-4 lg:mr-0">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Search
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    onChange={(e) => handleChange(e)}
                    id="search"
                    name="search"
                    className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Search requests..."
                    type="search"
                  />
                </div>
              </div>
            ) : (
              <></>
            )
          ) : (
            <Link to="/">
              <img
                src="/images/victoria.png"
                alt="Victoria"
                className="h-12 w-auto"
                style={{
                  objectFit: "contain",
                  filter: "drop-shadow(0 0 20px rgba(65, 105, 225, 0.3))",
                }}
              />
            </Link>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {user?.accountStatus === "ACTIVE" && (
            <>
              <NotificationBell />
              <Button onClick={() => navigate("/dashboard/settings")} variant="outline" size="sm">
                <User className="h-4 w-4" />
              </Button>
            </>
          )}
          <Button variant="outline" size="sm" onClick={handleLogout}>
            <LogOut className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </header>
  );
}
