import "regenerator-runtime/runtime";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { AdminProvider } from "./context/AdminContext";
import { RequestsProvider } from "./context/RequestsContext";
import { NotificationProvider } from "./context/NotificationContext";
import { initializeFirestore } from "./lib/initializeData";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  useEffect(() => {
    // Initialize Firestore with default data
    initializeFirestore().catch(console.error);
  }, []);

  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <AdminProvider>
            <RequestsProvider>
              <NotificationProvider>
                <AppRoutes />
                <ToastContainer />
              </NotificationProvider>
            </RequestsProvider>
          </AdminProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}
