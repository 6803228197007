import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { app } from './firebase/app';

const storage = getStorage(app);
const LOGO_STORAGE_PATH = 'logos';

interface LogoStorage {
  icon: string | null;
  full: string | null;
}

export const logoStorage = {
  get: async (): Promise<LogoStorage> => {
    // Default logos from public directory
    const defaultLogos = {
      icon: '/images/logo-icon.png',
      full: '/images/logo-full.png'
    };

    try {
      // Try localStorage first as it's faster and works offline
      const stored = localStorage.getItem('victoria_logos');
      if (stored) {
        try {
          const parsedLogos = JSON.parse(stored);
          if (parsedLogos.icon && parsedLogos.full) {
            return parsedLogos;
          }
        } catch (e) {
          console.warn('Failed to parse stored logos:', e);
        }
      }

      // Return default logos if not in production
      if (!import.meta.env.PROD) {
        return defaultLogos;
      }

      // Try Firebase Storage
      try {
        const iconRef = ref(storage, `${LOGO_STORAGE_PATH}/icon.png`);
        const fullRef = ref(storage, `${LOGO_STORAGE_PATH}/full.png`);

        const [iconUrl, fullUrl] = await Promise.all([
          getDownloadURL(iconRef).catch(() => defaultLogos.icon),
          getDownloadURL(fullRef).catch(() => defaultLogos.full)
        ]);

        const logos = {
          icon: iconUrl,
          full: fullUrl
        };

        // Cache in localStorage
        localStorage.setItem('victoria_logos', JSON.stringify(logos));
        return logos;
      } catch (error) {
        console.warn('Failed to fetch logos from Firebase:', error);
        return defaultLogos;
      }
    } catch (error) {
      console.warn('Error getting logos:', error);
      return defaultLogos;
    }
  },

  set: async (type: 'icon' | 'full', dataUrl: string): Promise<boolean> => {
    try {
      // Create a storage reference with metadata
      const logoRef = ref(storage, `${LOGO_STORAGE_PATH}/${type}.png`);
      
      // Upload with metadata
      const metadata = {
        contentType: 'image/png',
        cacheControl: 'public, max-age=31536000'
      };

      // Upload the base64 data
      const base64Data = dataUrl.split(',')[1];
      await uploadString(logoRef, base64Data, 'base64', metadata);
      
      // Update localStorage
      const current = await logoStorage.get();
      const updated = { ...current, [type]: dataUrl };
      localStorage.setItem('victoria_logos', JSON.stringify(updated));
      
      return true;
    } catch (error) {
      console.error('Error saving logo:', error);
      return false;
    }
  }
};