import { useEffect, useState } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { User } from "@/lib/types";
import SuperAdminTable from "@/components/SuperAdminsTable";

const SuperAdmins = () => {
  const [superAdmins, setSuperAdmins] = useState<User[]>([]);

  useEffect(() => {
    const getAllSuperAdmins = async () => {
      const querry = query(
        collection(db, "users"),
        where("role", "==", "SUPER_ADMIN")
      );

      const unsubUsers = onSnapshot(
        querry,
        (snapshot: any) => {
          const users = snapshot.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          })) as any;
          setSuperAdmins(users)
        },
        (error: any) => {
          console.error("Error loading offices:", error);
        }
      );

      return () => {
        unsubUsers();
      };
    };

    getAllSuperAdmins();
  }, []);

  return <SuperAdminTable superAdmins={superAdmins} />;
};

export default SuperAdmins;
