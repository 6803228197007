import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyEmail } from '@/lib/auth';
import Button from '@/components/ui/Button';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (!code) {
      setStatus('error');
      setError('Invalid verification link');
      return;
    }

    verifyEmail(code)
      .then(() => {
        setStatus('success');
      })
      .catch((err) => {
        setStatus('error');
        setError(err.message);
      });
  }, [searchParams]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0A0B1A] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        {status === 'verifying' && (
          <div>
            <h1 className="text-2xl font-bold text-white mb-4">Verifying your email...</h1>
            <p className="text-gray-400">Please wait while we verify your email address.</p>
          </div>
        )}

        {status === 'success' && (
          <div>
            <h1 className="text-2xl font-bold text-white mb-4">Email Verified!</h1>
            <p className="text-gray-400 mb-8">Your email has been successfully verified.</p>
            <Button onClick={() => navigate('/login')}>
              Sign in to your account
            </Button>
          </div>
        )}

        {status === 'error' && (
          <div>
            <h1 className="text-2xl font-bold text-white mb-4">Verification Failed</h1>
            <p className="text-red-400 mb-8">{error}</p>
            <Button onClick={() => navigate('/login')}>
              Return to Sign In
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}