import { useEffect, useRef, useState } from "react";
import { Bell } from "lucide-react";
import { useNotifications } from "@/context/NotificationContext";
import { useAuth } from "@/context/AuthContext";
import Button from "./ui/Button";
import { useNavigate } from "react-router-dom";
import { AppNotification } from "@/lib/types";

export default function NotificationBell() {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useNotifications();
  const { state: authState } = useAuth();
  const navigate = useNavigate();

  if (!authState.user) return null;

  const userNotifications = state.notifications.filter(
    (notification) => notification.userId === authState.user?.id
  );

  const unreadCount = userNotifications.filter((n) => !n.read).length;

  const handleNotificationClick = (notification: AppNotification) => {
    dispatch({ type: "MARK_AS_READ", payload: notification.id });
    setIsOpen(false);

    if (authState.isAuthenticated) {
      navigate(`/dashboard/requests`, {
        state: { scrollToId: notification.requestId },
      });
    }
  };

  const markAllAsRead = () => {
    if (authState.user) {
      dispatch({ type: "MARK_ALL_AS_READ", payload: authState.user.id } as any);
    }
  };

  const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };

      document.addEventListener("mouseup", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside);

      return () => {
        document.removeEventListener("mouseup", handleClickOutside);
        document.removeEventListener("touchend", handleClickOutside);
      };
    }, [isOpen]);

    return ref;
  };

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });
  return (
    <div ref={ref} className="md:relative">
      <Button
        variant="outline"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
        className="relative"
      >
        <Bell className="h-4 w-4" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1  md:right-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {unreadCount}
          </span>
        )}
      </Button>

      {isOpen && (
        <div className="absolute right-6 lg:right-0 mt-2 w-52 lg:w-80 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-10">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-sm font-medium text-gray-900">
                Notifications
              </h3>
              {unreadCount > 0 && (
                <button
                  onClick={markAllAsRead}
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Mark all as read
                </button>
              )}
            </div>
            <div className="space-y-4 max-h-96 overflow-y-auto">
              {userNotifications.length === 0 ? (
                <p className="text-sm text-gray-500 text-center py-4">
                  No notifications
                </p>
              ) : (
                userNotifications.map((notification) => (
                  <button
                    key={notification.id}
                    onClick={() => handleNotificationClick(notification)}
                    className={`w-full text-left p-3 rounded-md transition-colors ${
                      notification.read
                        ? "bg-gray-50 hover:bg-gray-100"
                        : "bg-blue-50 hover:bg-blue-100"
                    }`}
                  >
                    <p className="text-sm font-medium text-gray-900">
                      {notification.title}
                    </p>
                    <p className="text-sm text-gray-500 mt-1">
                      {notification.message}
                    </p>
                    <p className="text-xs text-gray-400 mt-1">
                      {new Date(notification.createdAt).toLocaleString()}
                    </p>
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
