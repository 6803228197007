import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateId(): string {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

export function extractFileName(url:string) {
  const regex = /\/o\/([^?]*)/; // Regex to capture the path after '/o/'
  const match = url.match(regex);
  if (match && match[1]) {
      // URL decode and return the file name
      return decodeURIComponent(match[1]).split('/').pop();
  } else {
      console.error('Invalid URL');
      return null;
  }
}
