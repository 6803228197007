export async function retryOperation<T>(
  operation: () => Promise<T>,
  maxRetries = 3,
  baseDelay = 1000,
  operationName = 'Operation'
): Promise<T> {
  let lastError: any;
  let delay = baseDelay;
  
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error: any) {
      lastError = error;
      
      const isRetryableError = 
        error?.code === 'unavailable' ||
        error?.code === 'resource-exhausted' ||
        error?.code === 'network-request-failed' ||
        error?.code === 'permission-denied' ||
        error?.code === 'failed-precondition' ||
        error?.message?.includes('internal assertion failed');
      
      if (!isRetryableError || attempt === maxRetries) {
        console.error(`${operationName} failed after ${attempt} attempts:`, error);
        throw error;
      }

      console.warn(
        `${operationName} attempt ${attempt}/${maxRetries} failed, retrying in ${delay}ms:`,
        error?.message || error
      );

      await new Promise(resolve => setTimeout(resolve, delay));
      delay *= 2; // Exponential backoff
    }
  }

  throw lastError;
}

export async function withTimeout<T>(
  promise: Promise<T>,
  timeoutMs: number,
  operationName = 'Operation'
): Promise<T> {
  const timeout = new Promise<never>((_, reject) => {
    setTimeout(() => {
      reject(new Error(`${operationName} timed out after ${timeoutMs}ms`));
    }, timeoutMs);
  });

  return Promise.race([promise, timeout]);
}