import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import { Request, RequestComment } from "@/lib/types";
import { db } from "@/lib/firebase/app";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  doc,
  updateDoc,
  arrayUnion,
  or,
  and,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import { retryOperation } from "@/lib/firebase/utils";
import * as JsSearch from "js-search";

interface RequestsState {
  requests: Request[];
  suggestedRequests: Request[];
  // assignedRequest: Request[];
  loading: boolean;
  loadedSuggestion: boolean;
  error: string | null;
}

type RequestsAction =
  | { type: "SET_REQUESTS"; payload: Request[] }
  // | { type: "SET_ASSIGNED_REQUESTS"; payload: Request[] }
  | {
      type: "SEARCH";
      payload: {
        value: string;
      };
    }
  | { type: "REMOVE_SEARCH" }
  | { type: "ADD_REQUEST"; payload: Request }
  | { type: "UPDATE_REQUEST"; payload: Request }
  | { type: "DELETE_REQUEST"; payload: string }
  | {
      type: "ADD_COMMENT";
      payload: { requestId: string; comment: RequestComment };
    }
  | {
      type: "RELEASE_NOTE";
      payload: { requestId: string; comment: RequestComment };
    }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "ASSIGN_ADMIN"; payload: { requestId: string; adminId: string } };
interface RequestsContextType {
  state: RequestsState;
  dispatch: React.Dispatch<RequestsAction>;
}

const RequestsContext = createContext<RequestsContextType | null>(null);

const initialState: RequestsState = {
  requests: [],
  suggestedRequests: [],
  // assignedRequest : [],
  loading: false,
  error: null,
  loadedSuggestion: false,
};

function requestsReducer(
  state: RequestsState,
  action: RequestsAction
): RequestsState {
  var search = new JsSearch.Search("id");
  search.addIndex("title");
  search.addDocuments(state.requests);
  switch (action.type) {
    case "SET_REQUESTS":
      return {
        ...state,
        requests: action.payload,
        loading: false,
        error: null,
      };

    // case "SET_ASSIGNED_REQUESTS" :
    //   return {
    //     ...state,
    //     assignedRequest: action.payload,
    //     loading: false,
    //     error: null,
    //   }

    case "SEARCH":
      const suggestion = search.search(action?.payload?.value);
      return {
        ...state,
        suggestedRequests: suggestion as any,
        loadedSuggestion: true,
      };
    case "REMOVE_SEARCH":
      return {
        ...state,
        suggestedRequests: [],
        loadedSuggestion: false,
      };

    case "ADD_REQUEST":
      return {
        ...state,
        requests: [...state.requests, action.payload],
        error: null,
      };

    case "UPDATE_REQUEST": {
      const updatedRequests = state.requests.map((request) =>
        request.id === action.payload.id ? action.payload : request
      );
      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "DELETE_REQUEST":
      return {
        ...state,
        requests: state.requests.filter(
          (request) => request.id !== action.payload
        ),
        error: null,
      };

    case "ADD_COMMENT": {
      const { requestId, comment } = action.payload;
      const sanitizedComment = Object.fromEntries(
        Object.entries(comment).filter(([_, value]) => value !== undefined)
      );
      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            comments: [...(request.comments || []), comment],
            updatedAt: new Date().toISOString(),
          };
        }
        return request;
      });

      const updateFirestore = async () => {
        try {
          const requestRef = doc(db, "requests", requestId);
          await updateDoc(requestRef, {
            comments: arrayUnion(sanitizedComment),
          });
        } catch (error) {
          console.error("Failed to update Firestore:", error);
        }
      };

      updateFirestore();

      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "RELEASE_NOTE": {
      const { requestId, comment } = action.payload;
      const sanitizedComment = Object.fromEntries(
        Object.entries(comment).filter(([_, value]) => value !== undefined)
      );
      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            comments: [...(request.comments || []), comment],
            updatedAt: new Date().toISOString(),
            originalAnalysis: {
              title: request?.originalAnalysis?.title || "",
              category: request?.originalAnalysis?.category || "",
              aiResponse: "",
            },
          };
        }
        return request;
      });

      const updateFirestore = async () => {
        try {
          const requestRef = doc(db, "requests", requestId);
          await updateDoc(requestRef, {
            comments: arrayUnion(sanitizedComment),
            "originalAnalysis.aiResponse": "",
          });
        } catch (error) {
          console.error("Failed to update Firestore:", error);
        }
      };

      updateFirestore();

      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "ASSIGN_ADMIN": {
      const { requestId, adminId } = action.payload;

      const updatedRequests = state.requests.map((request) => {
        if (request.id === requestId) {
          return {
            ...request,
            [adminId]: adminId,
          };
        }
        return request;
      });

      // const updateFirestore = async () => {
      //   try {
      //     const requestRef = doc(db, "requests", requestId);
      //     await updateDoc(requestRef, {
      //       adminId: adminId,
      //     });
      //   } catch (error) {
      //     console.error("Failed to update Firestore:", error);
      //   }
      // };

      // updateFirestore();

      return {
        ...state,
        requests: updatedRequests,
        error: null,
      };
    }

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
        error: null,
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export const updateRequest = async (request: any) => {
  try {
    const requestRef = doc(db, "requests", request.id);
    await updateDoc(requestRef, {
      ...request,
      updatedAt: new Date().toISOString(),
    });
    console.log("Request updated successfully in Firestore");
  } catch (error) {
    console.error("Error updating request in Firestore:", error);
    throw error;
  }
};

export function RequestsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(requestsReducer, initialState);
  const { state: authState } = useAuth();

  useEffect(() => {
    let unsubscribe: any = () => {};

    const setupRequestsListener = async () => {
      // Only proceed if user is authenticated
      if (!authState.isAuthenticated || !authState.user) {
        dispatch({ type: "SET_REQUESTS", payload: [] });
        return;
      }

      try {
        dispatch({ type: "SET_LOADING", payload: true });

        const setupListener = async () => {
          // Create query based on user role
          const requestsRef = collection(db, "requests");
          let requestsQuery = query(requestsRef, orderBy("createdAt", "desc"));

          // Only FOR ADMINS AS (Firestore does not support the logical OR condition directly across different fields in a single query,)
          // let adminQuery = query(requestsRef, orderBy("createdAt", "desc"));
          // let officeCategoryQuery = query(requestsRef, orderBy("createdAt", "desc"));

          if (
            authState?.user !== null &&
            authState?.user.role === "SUPER_ADMIN"
          ) {
            requestsQuery = query(
              requestsRef,
              where("workspaceId", "==", authState.user.workspaceId)
            );
          } else if (
            authState?.user !== null &&
            authState?.user.role === "ADMIN"
          ) {
            // adminQuery = query(
            //   requestsRef,
            //   where("adminId", "==", authState?.user?.id)
            // );
            // officeCategoryQuery = query(
            //   requestsRef,
            //   where("officeId", "in", authState?.user?.officeIds),
            //   where("categoryId", "in", authState?.user?.categoryIds)
            // );
            requestsQuery = query(
              requestsRef,
              or(
                where("adminId", "==", authState?.user?.id),
                and(
                  where("adminId", "==", null),
                  where("officeId", "in", authState?.user?.officeIds),
                  where("categoryId", "in", authState?.user?.categoryIds)
                )
              )
              // where("officeId", "in", authState?.user?.officeIds),
              // where("categoryId", "in", authState?.user?.categoryIds),
              // where("adminId", "==", authState?.user?.id)
            );
          } else {
            requestsQuery = query(
              requestsRef,
              where("agentId", "==", authState?.user?.id),
              orderBy("createdAt", "desc")
            );
          }

          // Set up real-time listener with retry
          return retryOperation(
            () =>
              new Promise((resolve, reject) => {
                // if (authState?.user?.role !== "ADMIN") {
                //   const unsubscribe = onSnapshot(
                //     requestsQuery,
                //     (snapshot) => {
                //       const requests = snapshot.docs.map(
                //         (doc) =>
                //           ({
                //             id: doc.id,
                //             ...doc.data(),
                //             comments: doc.data().comments || [],
                //           } as Request)
                //       );
                //       dispatch({ type: "SET_REQUESTS", payload: requests });
                //     },
                //     (error) => {
                //       // Only show error for authenticated users with permission issues
                //       if (
                //         error.code === "permission-denied" &&
                //         authState.isAuthenticated
                //       ) {
                //         console.error("Error fetching requests:", error);
                //         dispatch({
                //           type: "SET_ERROR",
                //           payload:
                //             "You do not have permission to access these requests.",
                //         });
                //       }
                //       reject(error);
                //     }
                //   );
                //   resolve(unsubscribe);
                // } else {
                //   const unsubscribeAdminQuery = onSnapshot(
                //     adminQuery,
                //     (snapshot) => {
                //       const requests = snapshot.docs.map(
                //         (doc) =>
                //           ({
                //             id: doc.id,
                //             ...doc.data(),
                //             comments: doc.data().comments || [],
                //           } as Request)
                //       );
                //       dispatch({ type: "SET_ASSIGNED_REQUESTS", payload: requests });
                //     },
                //     (error) => {
                //       if (
                //         error.code === "permission-denied" &&
                //         authState.isAuthenticated
                //       ) {
                //         console.error("Error fetching requests:", error);
                //         dispatch({
                //           type: "SET_ERROR",
                //           payload:
                //             "You do not have permission to access these requests.",
                //         });
                //       }
                //       reject(error);
                //     }
                //   );
                //   const unsubscribeOfficeCategoryQuery = onSnapshot(
                //     officeCategoryQuery,
                //     (snapshot) => {
                //       const requests = snapshot.docs.map(
                //         (doc) =>
                //           ({
                //             id: doc.id,
                //             ...doc.data(),
                //             comments: doc.data().comments || [],
                //           } as Request)
                //       );
                //       dispatch({ type: "SET_REQUESTS", payload: requests });
                //     },
                //     (error) => {
                //       if (
                //         error.code === "permission-denied" &&
                //         authState.isAuthenticated
                //       ) {
                //         console.error("Error fetching requests:", error);
                //         dispatch({
                //           type: "SET_ERROR",
                //           payload:
                //             "You do not have permission to access these requests.",
                //         });
                //       }
                //       reject(error);
                //     }
                //   );
                //   resolve(()=>{
                //     unsubscribeAdminQuery()
                //     unsubscribeOfficeCategoryQuery()
                //   });
                // }
                const unsubscribe = onSnapshot(
                  requestsQuery,
                  (snapshot) => {
                    const requests = snapshot.docs.map(
                      (doc) =>
                        ({
                          id: doc.id,
                          ...doc.data(),
                          comments: doc.data().comments || [],
                        } as Request)
                    );

                    dispatch({ type: "SET_REQUESTS", payload: requests });
                  },
                  (error) => {
                    // Only show error for authenticated users with permission issues
                    if (
                      error.code === "permission-denied" &&
                      authState.isAuthenticated
                    ) {
                      console.error("Error fetching requests:", error);
                      dispatch({
                        type: "SET_ERROR",
                        payload:
                          "You do not have permission to access these requests.",
                      });
                    }
                    reject(error);
                  }
                );
                resolve(unsubscribe);
              }),
            3,
            1000,
            "Setting up requests listener"
          );
        };

        unsubscribe = await setupListener();
      } catch (error: any) {
        // Only show errors if authenticated
        if (authState.isAuthenticated) {
          console.error("Error setting up requests listener:", error);
          dispatch({
            type: "SET_ERROR",
            payload: "Failed to load requests. Please try again later.",
          });
        }
      }
    };

    setupRequestsListener();

    // Cleanup listener on unmount or when auth state changes
    return () => unsubscribe();
  }, [authState.isAuthenticated, authState.user]);

  return (
    <RequestsContext.Provider value={{ state, dispatch }}>
      {children}
    </RequestsContext.Provider>
  );
}

export function useRequests() {
  const context = useContext(RequestsContext);
  if (!context) {
    throw new Error("useRequests must be used within a RequestsProvider");
  }
  return context;
}
